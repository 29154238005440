// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = 'tokens.uniswap.eth'
// export const DEFAULT_TOKEN_LIST_URL = 'https://api.udonswap.org/tokens'

export const DEFAULT_LIST_OF_LISTS: string[] = [
  DEFAULT_TOKEN_LIST_URL,
  // 'https://ipfs.io/ipfs/QmSi3W99q68E8xPrgshiPDgfwxNCGL35htJ1fvaNfKz5D8?filename=modeTokens.json',
  // 'https://sepolia.explorer.mode.network/api/v2/tokens?type=ERC-20/',
  // 'tokens.1inch.eth', // 1inch
  'https://api.udonswap.org/tokens'
  // 'synths.snx.eth',
  // 'tokenlist.dharma.eth',
  // 'defi.cmc.eth',
  // 'erc20.cmc.eth',
  // 'stablecoin.cmc.eth',
  // 'tokenlist.zerion.eth',
  // 'tokenlist.aave.eth',
  //   'https://www.coingecko.com/tokens_list/uniswap/defi_100/v_0_0_0.json',
  //   'https://app.tryroll.com/tokens.json',
  //   'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json',
  //   // 'https://defiprime.com/defiprime.tokenlist.json',
  //   'https://umaproject.org/uma.tokenlist.json',
  //   'https://ipfs.io/ipfs/QmSi3W99q68E8xPrgshiPDgfwxNCGL35htJ1fvaNfKz5D8?filename=modeTokens.json'
]
